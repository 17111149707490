<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('course.title')"
        left-arrow
        @click-left="onClickLeft"
      >
      </van-nav-bar>
    </div>
    <div class="maincontent">
      <van-tabs color="#004ea3" @click="getlist" v-model="activeid">
        <van-tab :title="lang === 'zh' ? item.name : item[`name_${lang}`]" v-for="item in types" :key="item.id">
          <van-cell
            :title="lang === 'zh' ? d.title : d[`title_${lang}`]"
            is-link
            :to="'/course/detail/' + d.id"
            v-for="d in list"
            :key="d.id"
          />
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: localStorage.getItem('lang') || 'zh',
      list: [],
      types: [],
      activeid: 0
    }
  },
  created() {
    this.gettype()
  },
  methods: {
    async gettype() {
      const { data } = await this.$http.get('/home/home/coursetype')
      if (data) {
        if (data.code === 200) {
          this.types = data.data
          this.getlist(data.data[this.activeid].id)
        }
      }
    },
    async getlist() {
      const { data } = await this.$http.get(
        '/home/home/courselist/type/' + this.types[this.activeid].id
      )
      if (data) {
        if (data.code === 200) {
          this.list = data.data
        }
      }
    },
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.maincontent {
  padding: 70px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f7f7f7;
  height: 100vh;
  /deep/.van-tabs__nav--line {
    box-sizing: initial;
    height: 100%;
    padding-bottom: 15px;
  }
  /deep/.van-tab {
    font-size: 1rem;
    background: #f7f7f7;
  }
  /deep/.van-tab--active {
    color: #004ea3;
  }
  /deep/.van-tabs__content,
  /deep/.van-tabs__nav--card .van-tab.van-tab--active {
    background-color: #fff;
    color: #000;
  }
  .van-cell__title {
    text-align: left;
  }
}
</style>
